import React, { useEffect, useState } from 'react';

// material-ui
import { Typography } from '@material-ui/core';

// project imports
import NavGroup from './NavGroup';
import menuItem from './../../../../menu-items';
import { useSelector } from 'react-redux';

//-----------------------|| SIDEBAR MENU LIST ||-----------------------//

const MenuList = () => {
    const account = useSelector((state)=>state.account);
    const [items, setItems] = useState(menuItem.items);

    useEffect(() => {
        var itemsTemp =  menuItem.items.filter((item)=>{
             if(account.admin && item.type === "group" && item.id === 'ai-tool'){
                 return false;
             }
             if(!account.admin && item.type === "group" && item.id === 'dashboard'){
                 return false;
             }
             return true;
         });
         setItems(itemsTemp);
     }, [setItems]);


    const navItems = items.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return navItems;
};

export default MenuList;
