// assets
import { IconBrandChrome, IconHelp, IconSitemap, IconUser } from '@tabler/icons';

// constant
const icons = {
    IconBrandChrome: IconBrandChrome,
    IconHelp: IconHelp,
    IconSitemap: IconSitemap,
    IconUser:IconUser,
};

//-----------------------|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||-----------------------//

export const other = {
    id: 'other-pages',
    type: 'group',
    children: [
        // {
        //     id: 'sample-page',
        //     title: 'Sample Page',
        //     type: 'item',
        //     url: '/sample-page',
        //     icon: icons['IconBrandChrome'],
        //     breadcrumbs: false
        // },
        // {
        //     id: 'user-list',
        //     title: 'Users',
        //     type: 'item',
        //     url: '/user-list',
        //     icon: icons['IconUser'],
        //     breadcrumbs: false
        // },
        // {
        //     id: 'documentation',
        //     title: 'Documentation',
        //     type: 'item',
        //     url: 'https://docs.appseed.us/products/react/node-js-berry-dashboard',
        //     icon: icons['IconHelp'],
        //     external: true,
        //     target: true
        // }
    ]
};
