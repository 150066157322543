// assets
import { IconBrandChrome, IconHelp, IconSitemap, IconBrain } from '@tabler/icons';

// constant
const icons = {
    IconBrandChrome: IconBrandChrome,
    IconHelp: IconHelp,
    IconSitemap: IconSitemap,
    IconBrain: IconBrain,
};

//-----------------------|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||-----------------------//

export const aiTool = {
    id: 'ai-tool',
    title: 'AI Tool',
    type: 'group',
    children: [
        // {
        //     id: 'default',
        //     title: 'AI Tool',
        //     type: 'item',
        //     url: '/ai-tool/default',
        //     icon: icons['IconBrain'],
        //     breadcrumbs: false
        // },
        // {
        //     id: 'documentation',
        //     title: 'Documentation',
        //     type: 'item',
        //     url: 'https://docs.appseed.us/products/react/node-js-berry-dashboard',
        //     icon: icons['IconHelp'],
        //     external: true,
        //     target: true
        // }
    ]
};
