import { dashboard } from './dashboard';
import { utilities } from './utilities';
import { other } from './other';
import { aiTool } from './ai-tool';

//-----------------------|| MENU ITEMS ||-----------------------//

const menuItems = {
    items: [
        aiTool,
        dashboard,
        //  utilities, 
        //  other
        ]
};

export default menuItems;
