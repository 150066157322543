// assets
import { IconDashboard, IconDeviceAnalytics, IconUser } from '@tabler/icons';

// constant
const icons = {
    IconDashboard: IconDashboard,
    IconUser: IconUser,
    IconDeviceAnalytics
};

//-----------------------|| DASHBOARD MENU ITEMS ||-----------------------//

export const dashboard = {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'Dashboard',
            type: 'item',
            url: '/dashboard/default',
            icon: icons['IconDashboard'],
            breadcrumbs: false
        },
        {
            id: 'user-list',
            title: 'Users',
            type: 'item',
            url: '/user-list',
            icon: icons['IconUser'],
            breadcrumbs: false
        },
        {
            id: 'lab-list',
            title: 'Labs',
            type: 'item',
            url: '/lab/list',
            icon: icons['IconUser'],
            breadcrumbs: false
        },
        {
            id: 'hom-list',
            title: 'Homes',
            type: 'item',
            url: '/home/list',
            icon: icons['IconUser'],
            breadcrumbs: false
        },
    ]
};
